var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 16 14", width: _vm.width } }, [
    _c(
      "defs",
      [
        _c(
          "linearGradient",
          { attrs: { id: _vm.id } },
          [
            _c("stop", {
              attrs: {
                offset: `${_vm.percentageFill}%`,
                "stop-color": _vm.activeColor,
              },
            }),
            _vm._v(" "),
            _c("stop", {
              attrs: {
                offset: `${_vm.percentageFill}%`,
                "stop-color": _vm.inactiveColor,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: `url(#${_vm.id})`,
        d:
          "M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }