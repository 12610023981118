<template>
    <SocialReviews
        :show-review-list="showReviewList"
        :header-style="headerStyle"
        :header-text="headerText"
        :header-additional-classes="headerAdditionalClasses"
    />
</template>

<script>
import SocialReviews from '@/apps/about_us/components/SocialReviews';

export default {
    components: {
        SocialReviews,
    },
    props: {
        showReviewList: {
            type: Boolean,
            default: true,
            required: false,
        },
        headerStyle: {
            type: String,
            default: 'h1',
            required: false,
        },
        headerAdditionalClasses: {
            type: String,
            default: '',
            required: false,
        },
        headerText: {
            type: String,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/bs4/_variables.scss";
:deep(.reviews__aggregate) {
    font-family: $font-family-sans-serif;
}
</style>
