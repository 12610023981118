var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 72 72.26",
        width: "72px",
        height: "72px",
      },
    },
    [
      _c("g", { attrs: { "data-name": "Layer 2" } }, [
        _c("path", {
          attrs: {
            d:
              "M66.68 0H5.32A5.26 5.26 0 000 5.19v61.88a5.26 5.26 0 005.32 5.19h33.12V44.35h-9.38V33.46h9.38v-8q0-6.85 3.82-10.6t10.21-3.76a63.27 63.27 0 018.35.42v9.72h-5.73q-3.18 0-4.29 1.32a6 6 0 00-1.1 3.94v6.94h10.74L59 44.35h-9.3v27.91h17a5.26 5.26 0 005.3-5.19V5.19A5.26 5.26 0 0066.68 0z",
            fill: "#3d5a96",
            "data-name": "Layer 1",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }