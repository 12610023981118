import '@ungap/custom-elements';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import VueI18n from 'vue-i18n';
// import Vue2Filters from 'vue2-filters';
import en from '@/apps/about_us/locales/en.json';
import SocialReviews from './SocialReviews';

Vue.use(vueCustomElement);
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    messages: {
        en,
    },
});

Vue.customElement('social-reviews', SocialReviews, {
    // https://github.com/karol-f/vue-custom-element/issues/87#issuecomment-690216181
    beforeCreateVueInstance(RootComponentDefinition) {
        // eslint-disable-next-line no-param-reassign
        RootComponentDefinition.i18n = i18n;
        return RootComponentDefinition;
    },
});
