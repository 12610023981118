<template>
    <svg
        viewBox="0 0 16 14"
        :width="width"
    >
        <defs>
            <linearGradient
                :id="id"
            >
                <stop
                    :offset="`${percentageFill}%`"
                    :stop-color="activeColor"
                />
                <stop
                    :offset="`${percentageFill}%`"
                    :stop-color="inactiveColor"
                />
            </linearGradient>
        </defs>
        <path
            :fill="`url(#${id})`"
            d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        starRating: {
            type: Number,
            required: true,
        },
        activeColor: {
            type: String,
            default: '#F6B140',
        },
        inactiveColor: {
            type: String,
            default: '#bababa',
        },
        width: {
            type: Number,
            default: 34,
        },
    },
    computed: {
        // percentageFill represents the percentage of activeColor shown on the star. `percentageFill()`
        // fill defines thresholds based on the passed in starRating prop.
        percentageFill() {
            // ratingMap takes the form: { theshold upper bound : % star filled yellow, }
            const ratingMap = {
                20: 0, 40: 35, 60: 50, 80: 65,
            };
            const ratingKeys = Object.keys(ratingMap);
            const ratingValues = Object.values(ratingMap);
            for (let i = 0; i < ratingKeys.length; i += 1) {
                if (this.starRating < ratingKeys[i]) {
                    return ratingValues[i];
                }
            }
            return 100;
        },
    },
};
</script>
