<template>
    <div class="social-reviews">
        <div
            class="container mb-5"
        >
            <div class="review-hero">
                <!-- Reviews header -->
                <component
                    :is="headerStyle"
                    :class="['text-dark font-header text-center mb-4 mb-md-5', headerAdditionalClasses]"
                >
                    {{ headerText || $t('components.SocialReviews.reviewTitle') }}
                </component>

                <!-- Aggregate reviews -->
                <div class="d-md-flex mb-5 reviews__aggregate">
                    <!-- Google reviews aggregate -->
                    <div
                        v-if="googleRating"
                        class="d-flex mx-auto mr-md-4 mr-lg-5 mb-5 mb-md-0 align-items-center reviews__google"
                    >
                        <div class="mr-2">
                            <img
                                src="~es-static/img/learn/about-us/company/social-media/google-icon.png"
                                :alt="$t('components.SocialReviews.googleIcon')"
                            >
                        </div>

                        <div>
                            <EsStarRating
                                :rating="Number(googleRating)"
                                :name="'google-reviews'"
                            />

                            <div class="d-flex justify-content-between align-items-end">
                                <span
                                    class="aggregate-rating"
                                    :aria-label="$t('components.SocialReviews.googleRatingLabel')"
                                >{{ $t('components.SocialReviews.googleRatingValue', {rating: googleRating}) }}</span>
                                <span class="mb-1 pl-2">{{ googleTotal }}</span>
                            </div>
                        </div>
                    </div> <!-- Google reviews aggregate -->

                    <!-- Facebook reviews aggregate -->
                    <div class="d-flex mx-auto ml-md-4 ml-lg-5 align-items-center reviews__facebook">
                        <div class="mr-3">
                            <FacebookIcon />
                        </div>

                        <div>
                            <EsStarRating
                                :rating="facebookRating"
                                :name="'facebook-reviews'"
                            />

                            <div class="d-flex justify-content-between align-items-end">
                                <span
                                    class="aggregate-rating"
                                    :aria-label="$t('components.SocialReviews.facebookRatingLabel')"
                                >{{ $t('components.SocialReviews.facebookRatingValue',
                                       {rating: facebookRating}) }}</span>
                                <span class="mb-1 pl-2">{{ facebookTotal }}</span>
                            </div>
                        </div>
                    </div>
                </div> <!-- Reviews aggregate -->
            </div> <!-- Reviews hero -->

            <!-- Review list -->
            <div class="row">
                <div class="col-xxl-10 offset-xxl-1">
                    <div
                        v-if="showReviewList && reviews.length > 0"
                        class="list-unstyled d-flex px-4 flex-nowrap align-items-start
                    justify-content-center review-details-wrapper"
                    >
                        <div
                            v-for="(review, index) in reviews"
                            :key="index"
                            :class="['text-center review-detail', review.visibility_classes]"
                        >
                            <div class="review-detail__photo">
                                <img
                                    class="profile-photo"
                                    :src="review.profile_photo_url"
                                >
                            </div>
                            <div class="d-flex justify-content-center">
                                <div
                                    :class="[
                                        'border-0 mx-3 px-3 mb-3 pb-3 rounded-lg review-detail__card',
                                        {'text-collapsed': !review.show_full_text}
                                    ]"
                                >
                                    <div class="review-detail__source">
                                        <span class="review-card__name">{{ review.author_name }}</span>
                                    </div>

                                    <div class="mb-2 review-detail__rating">
                                        <span
                                            :aria-label="$t('components.SocialReviews.customerRatingLabel',
                                                            {rating: review.rating})"
                                        >
                                            <EsStarRating
                                                :rating="review.rating"
                                                :name="`user-review-${index}`"
                                                :star-width="26"
                                            />
                                        </span>
                                    </div>

                                    <div
                                        class="review-detail__text"
                                        @click="toggleReadMore(review, index)"
                                    >
                                        {{ textToShow[index] }}
                                    </div>

                                    <div
                                        v-show="!review.show_full_text"
                                        class="text-expand"
                                        @click="toggleReadMore(review, index)"
                                    >
                                        {{ $t('components.SocialReviews.readMore') }}
                                    </div>
                                    <div
                                        v-show="review.show_full_text"
                                        class="text-expand"
                                        @click="toggleReadMore(review, index)"
                                    >
                                        {{ $t('components.SocialReviews.readLess') }}
                                    </div>
                                </div> <!-- Review card -->
                            </div>
                        </div> <!-- Review slide -->
                    </div> <!-- Review list -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* globals Sentry */
import EsStarRating from '@/apps/about_us/components/EsStarRating';
import FacebookIcon from './svgs/FacebookIcon';

const PLACE_ID = 'ChIJUTj3jYZw44kRVm1S8nzSiqc';
const PLACES_API_URL = 'https://maps.googleapis.com/maps/api/js?libraries=places'
    + `&key=${process.env.GMAP_API_KEY_REVIEWS}`;

/* Div element required to create GMaps PlacesService */
let googleMapsDOMTarget;
let googleMapsApi;

export default {
    components: {
        FacebookIcon,
        EsStarRating,
    },
    props: {
        showReviewList: {
            type: Boolean,
            default: true,
            required: false,
        },
        headerStyle: {
            type: String,
            default: 'h1',
            required: false,
        },
        headerAdditionalClasses: {
            type: String,
            default: '',
            required: false,
        },
        headerText: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            googleRating: undefined,
            googleTotal: '600+ reviews',
            // TODO: Get actual FB review data
            facebookRating: 5.0,
            facebookTotal: '150+ reviews',
            reviews: [],
            reviewTexts: ['', '', '', ''],
        };
    },
    computed: {
        textToShow() {
            return this.reviewTexts;
        },

    },
    created() {
        /* The GMaps API PlacesService requires a Map, which must be created inside an HTML container:
        https://developers.google.com/maps/documentation/javascript/reference/map#Map.constructor.
        The map will not be displayed.
        */

        /* Prevent navigation between pages from creating multiple map elements */
        googleMapsDOMTarget = document.getElementById('jsid-review-temp-map');
        if (!googleMapsDOMTarget) {
            googleMapsDOMTarget = document.createElement('div');
            googleMapsDOMTarget.id = 'jsid-review-temp-map';
            document.body.appendChild(googleMapsDOMTarget);
        }

        googleMapsApi = document.getElementById('google-maps-api-script');
        if (googleMapsApi) {
            /* If API previously loaded, get reviews */
            this.handle_get_google_reviews();
        } else {
            /* Load GMaps API and call initMaps when done */
            googleMapsApi = document.createElement('script');
            googleMapsApi.async = true;
            googleMapsApi.id = 'google-maps-api-script';
            googleMapsApi.setAttribute('src', `${PLACES_API_URL}&callback=initMaps`);
            document.head.appendChild(googleMapsApi);
        }

        const comp = this; // need pointer to component for use in initMaps, since its scope is `window`
        window.initMaps = function initMaps() {
            if (window.google && window.google.maps) {
                /* window is already loaded if navigating from another about-us page */
                if (document.readyState === 'complete') {
                    comp.handle_get_google_reviews();
                } else {
                    window.google.maps.event.addDomListener(window, 'load', () => {
                        comp.handle_get_google_reviews();
                    });
                }
            }
        };
    },
    methods: {
        compare_publish_time(review1, review2) {
            return review2.time - review1.time;
        },

        get_visibility_classes(index) {
            /* Helper to assign responsive visibility classes to elements created in a v-for loop. */
            const visibleClasses = ['d-block', 'd-none d-md-block', 'd-none d-xl-block', 'd-none d-xxl-block'];
            if (index < 4) {
                return visibleClasses[index];
            } return 'd-none';
        },

        get_short_text(text, length) {
            /* Find where to split a block of text to a given character length. */
            let sentenceEnd = -1;
            let sentencePause = -1;
            let wordBreak = -1;
            let end;
            let clamp = '...';
            for (let i = sentenceEnd; i < length; i += 1) {
                const char = text[i];
                if ('.?!\n'.includes(char)) {
                    sentenceEnd = i;
                } if (',-:;'.includes(char)) {
                    sentencePause = i;
                } if (' '.includes(char)) {
                    wordBreak = i;
                }
            }
            if (sentenceEnd > length * 0.75) {
                end = sentenceEnd + 1;
                clamp = '';
            } else if (sentencePause > length * 0.75) {
                end = sentencePause;
            } else if (wordBreak > 1) {
                end = wordBreak;
            } else {
                end = length;
            }
            return text.substr(0, end) + clamp;
        },

        toggleReadMore(review, index) {
            // eslint-disable-next-line no-param-reassign
            review.show_full_text = !review.show_full_text;
            if (review.show_full_text) {
                this.reviewTexts.splice(index, 1, review.text);
            } else {
                this.reviewTexts.splice(index, 1, review.short_text);
            }
        },

        handle_get_google_reviews() {
            /* Use GMaps API to lookup EnergySage and get its overall rating and review info */
            const map = new window.google.maps.Map(document.getElementById('jsid-review-temp-map'), {
                center: {
                    lat: 42.3557384,
                    lng: -71.0580463,
                },
                zoom: 17,
            });
            const service = new window.google.maps.places.PlacesService(map);
            const placeRequest = {
                placeId: PLACE_ID,
                // Avoid billing for Contact data that we don't need; we'll be charged for Atmosphere data
                // https://developers.google.com/maps/billing/understanding-cost-of-use#data-skus
                // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
                fields: ['rating', 'reviews'],
            };
            service.getDetails(placeRequest, (result, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    this.render_google_reviews(result);
                } else {
                    this.handle_google_reviews_load_failure();
                }
            });
        },

        handle_google_reviews_load_failure() {
            try {
                Sentry.captureMessage('Failed to load Google reviews', 'info');
            } catch (error) {
                throw new Error('Failed to load Google reviews.');
            }
        },

        filter_ratings(review) {
            return review.rating >= 4;
        },

        render_google_reviews(result) {
            /* Populate component data with overall rating and review data from the GMaps API */
            this.googleRating = result.rating.toFixed(1);
            this.four_plus_star_reviews = result.reviews.filter(this.filter_ratings);
            // component will store at most 3 reviews
            this.reviews = this.four_plus_star_reviews.sort(this.compare_publish_time).slice(0, 4);
            for (let i = 0; i < this.reviews.length; i += 1) {
                const review = this.reviews[i];
                review.visibility_classes = this.get_visibility_classes(i);
                review.short_text = this.get_short_text(review.text, 160);
                review.show_full_text = false;
                this.reviewTexts[i] = review.short_text;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/social-reviews.scss';
</style>
