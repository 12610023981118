<template>
    <!-- NOTE: EsStarRating can currently only be used to display ratings out of 5. -->
    <div
        class="star-aggregate"
    >
        <EsStar
            v-for="(starRating, i) in stars"
            :id="`${name}-${i}`"
            :key="`star-${i}`"
            :star-rating="starRating"
            :width="starWidth"
            class="m-1"
        />
    </div>
</template>

<script>
import EsStar from './svgs/EsStar';

export default {
    components: {
        EsStar,
    },
    props: {
        rating: {
            type: Number,
            required: true,
        },
        // Require a unique name for star review classes to be applied correctly
        name: {
            type: String,
            required: true,
        },
        starWidth: {
            type: Number,
            default: 34,
        },
    },
    computed: {
        stars() {
            // Return a list of ratings for each star in an EsStarRating.
            let rating = parseFloat(this.rating);
            const starRatings = [];
            for (let i = 0; i < 5; i += 1) {
                if (rating >= 1) {
                    starRatings.push(100);
                } else if (rating > 0) {
                    starRatings.push(Math.round(rating * 100));
                } else {
                    starRatings.push(0);
                }
                rating -= 1;
            }
            return starRatings;
        },
    },
};
</script>
