var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SocialReviews", {
    attrs: {
      "show-review-list": _vm.showReviewList,
      "header-style": _vm.headerStyle,
      "header-text": _vm.headerText,
      "header-additional-classes": _vm.headerAdditionalClasses,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }