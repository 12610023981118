var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "star-aggregate" },
    _vm._l(_vm.stars, function (starRating, i) {
      return _c("EsStar", {
        key: `star-${i}`,
        staticClass: "m-1",
        attrs: {
          id: `${_vm.name}-${i}`,
          "star-rating": starRating,
          width: _vm.starWidth,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }