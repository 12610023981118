var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social-reviews" }, [
    _c("div", { staticClass: "container mb-5" }, [
      _c(
        "div",
        { staticClass: "review-hero" },
        [
          _c(
            _vm.headerStyle,
            {
              tag: "component",
              class: [
                "text-dark font-header text-center mb-4 mb-md-5",
                _vm.headerAdditionalClasses,
              ],
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.headerText ||
                      _vm.$t("components.SocialReviews.reviewTitle")
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-md-flex mb-5 reviews__aggregate" }, [
            _vm.googleRating
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex mx-auto mr-md-4 mr-lg-5 mb-5 mb-md-0 align-items-center reviews__google",
                  },
                  [
                    _c("div", { staticClass: "mr-2" }, [
                      _c("img", {
                        attrs: {
                          src: require("es-static/img/learn/about-us/company/social-media/google-icon.png"),
                          alt: _vm.$t("components.SocialReviews.googleIcon"),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("EsStarRating", {
                          attrs: {
                            rating: Number(_vm.googleRating),
                            name: "google-reviews",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-end",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "aggregate-rating",
                                attrs: {
                                  "aria-label": _vm.$t(
                                    "components.SocialReviews.googleRatingLabel"
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.SocialReviews.googleRatingValue",
                                      { rating: _vm.googleRating }
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "mb-1 pl-2" }, [
                              _vm._v(_vm._s(_vm.googleTotal)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex mx-auto ml-md-4 ml-lg-5 align-items-center reviews__facebook",
              },
              [
                _c("div", { staticClass: "mr-3" }, [_c("FacebookIcon")], 1),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("EsStarRating", {
                      attrs: {
                        rating: _vm.facebookRating,
                        name: "facebook-reviews",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-end",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "aggregate-rating",
                            attrs: {
                              "aria-label": _vm.$t(
                                "components.SocialReviews.facebookRatingLabel"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.SocialReviews.facebookRatingValue",
                                  { rating: _vm.facebookRating }
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "mb-1 pl-2" }, [
                          _vm._v(_vm._s(_vm.facebookTotal)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xxl-10 offset-xxl-1" }, [
          _vm.showReviewList && _vm.reviews.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "list-unstyled d-flex px-4 flex-nowrap align-items-start justify-content-center review-details-wrapper",
                },
                _vm._l(_vm.reviews, function (review, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "text-center review-detail",
                        review.visibility_classes,
                      ],
                    },
                    [
                      _c("div", { staticClass: "review-detail__photo" }, [
                        _c("img", {
                          staticClass: "profile-photo",
                          attrs: { src: review.profile_photo_url },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "border-0 mx-3 px-3 mb-3 pb-3 rounded-lg review-detail__card",
                                { "text-collapsed": !review.show_full_text },
                              ],
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "review-detail__source" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "review-card__name" },
                                    [_vm._v(_vm._s(review.author_name))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mb-2 review-detail__rating" },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        "aria-label": _vm.$t(
                                          "components.SocialReviews.customerRatingLabel",
                                          { rating: review.rating }
                                        ),
                                      },
                                    },
                                    [
                                      _c("EsStarRating", {
                                        attrs: {
                                          rating: review.rating,
                                          name: `user-review-${index}`,
                                          "star-width": 26,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "review-detail__text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(review, index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.textToShow[index]) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !review.show_full_text,
                                      expression: "!review.show_full_text",
                                    },
                                  ],
                                  staticClass: "text-expand",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(review, index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.SocialReviews.readMore"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: review.show_full_text,
                                      expression: "review.show_full_text",
                                    },
                                  ],
                                  staticClass: "text-expand",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(review, index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.SocialReviews.readLess"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }